<template>
	<el-row :gutter="160">
		<el-col :span="24" style="text-align: left;">
			<el-dropdown @command="onSelectGroup">
				<el-button type="success" style="width:144px;">
					{{curGroupLabel}}<i class="el-icon-arrow-down el-icon--right"></i>
				</el-button>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item v-for="item in serverGroups" :key="item.id" :command="item" icon="el-icon-coin">
						{{item.name}}
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
            <el-button type="primary" icon="el-icon-upload2" @click="onClickUpload" style="margin-left: 10px;">{{labelBtnUpload}}</el-button>
            <el-button type="primary" icon="el-icon-brush" @click="onClickBatSetPKg" :disabled="noneSelectedRow">{{labelBtnBatMdf}}</el-button>
            <el-button type="success" icon="el-icon-refresh" @click="refreshServerList">刷新列表</el-button>
		</el-col>      
		<el-col :span="24">
			<el-table ref="multipleTable" :data="tableData" :height="tableHeight" border style="width: 100%" :row-class-name="tableRowClassName"
			:cell-style="{padding:4+'px'}" @selection-change="handleSelectionChange" class="serverlist">
				<el-table-column prop="id" label="id" type="selection"  width="90"> </el-table-column>
				<el-table-column prop="id" label="id"  width="90"> </el-table-column>
				<el-table-column prop="servername" label="区服serverid" width="160"> </el-table-column>
				<el-table-column label="操作" width="110">
					<template slot-scope="scope">
						<el-button @click.native.prevent="onClickEditServer(scope.row)" type="primary" icon="el-icon-edit" size="small">修改</el-button>
					</template>
				</el-table-column>                
				<el-table-column prop="servertitle" label="区服标题"></el-table-column>
				<el-table-column prop="pkgname" label="版本包"></el-table-column>
				<el-table-column prop="update_time" label="更新时间"></el-table-column>
			</el-table>
		</el-col>
		<!-- ========上传弹框BEGIN====== -->
		<el-dialog title="上传文件" :visible.sync="dialogUpload" width="480px" :close-on-click-modal="false" center class="dlgupload">
			<el-form>
				<el-form-item>
					<el-upload class="upload-demo" drag 
                    ref="upload"
                    :action="uploadAction"
                    :limit="1" 
					:file-list="uploadFileList"
                    :on-change="onUploadPreview"
                    :on-success="onUploadSuccess"
                    :on-remove="onUploadRemove"
                    :on-error="onUploadRemove"
                    :auto-upload="false">
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						<div class="el-upload__tip" slot="tip">只能上传zip文件，且不超过16MB</div>
					</el-upload>
				</el-form-item>
				<el-form-item>
					<el-input v-model="pkgtag" placeholder="请输入标签, 方便识别"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" icon="el-icon-check" @click="onCommitUploadPkg" :disabled="noneUploaded">确 定</el-button>
				<el-button icon="el-icon-close" @click="dialogUpload = false">取 消</el-button>
			</div>
		</el-dialog>
		<!-- ========分组管理弹框END====== -->       
		<!-- ========设置pkg弹框BEGIN====== -->
		<el-dialog :title="titleSetPkg" :visible.sync="dialogSetPkg" :close-on-click-modal="false" width="640px" center>
			<el-form>
                <el-form-item label="选择文件">
                    <el-select v-model="curPkgId" placeholder="请选择" value-key="name" style="width:100%;">
                        <el-option v-for="item in pkgList" :key="item.name" :label="item.name" :value="item">
                            <i class="el-icon-folder"></i>
                            <span>{{item.name}}</span>
                            <span style="float:right; color: #8492a6;">{{item.tag}}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择更新方式">
                    <el-select v-model="updateType" placeholder="更新方式" style="width: 100%;">
                        <el-option v-for="item in hotUpdates" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="选择想同步修改的在线参数" v-if="'config'==this.pkgtype"> -->
                <el-form-item label="选择想同步修改的在线参数" v-if="false">    
                    <el-table :data="onlineParams" border style="width: 100%" height="320" class="serverlist"
                        @selection-change="onlineParamSelectionChange" :cell-style="{padding:4+'px'}">
                        <el-table-column label="ID" prop="id" type="selection"></el-table-column>
                        <el-table-column label="名称" prop="name" show-overflow-tooltip></el-table-column>
                        <el-table-column label="描述" prop="desc" show-overflow-tooltip></el-table-column>                        
                    </el-table>
                </el-form-item>   
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" icon="el-icon-check" @click="onCommitSetPkg" :disabled="commitDisable">确 定</el-button>
				<el-button icon="el-icon-close" @click="dialogSetPkg = false">取 消</el-button>
			</div>
		</el-dialog>
		<!-- ========设置pkg弹框END====== -->
        <el-dialog title="提示" :visible.sync="dialogLoading" width="640px" :close-on-click-modal="false" :show-close="false">
          <span>查询中...</span>
        </el-dialog>
		<!-- ========提交设置pkg====== -->
        <el-dialog title="提示" :visible.sync="dialogUpdatePkg" width="640px" @close="onCloseCheckPkg" :close-on-click-modal="false">
            <span>{{tipPkgResult}}</span>
            <el-table :data="serverpkgs" border style="width: 100%" height="320" class="serverlist" :cell-style="pkgCellStype">
                <el-table-column label="ID" prop="id"></el-table-column>
                <el-table-column label="区服serverid" prop="name" show-overflow-tooltip></el-table-column>
                <el-table-column label="状态" prop="state" show-overflow-tooltip></el-table-column>                        
            </el-table>
        </el-dialog>
	</el-row>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
    import admzip from 'adm-zip'
    import axios from 'axios'
    import tool from "../../tool.js"
    
	export default {
		props: ['pkgtype'],
		data() {
			return {
                checkedPkgName: false,
                dialogLoading: false,
                onlineParams:[],
                pkgCenterApi: null,
                uploadAction: this.$store.state.game.pkgurl + '/sygame_upload',
				tdialogUpload: false,                
				dialogUpload: false,
				noneUploaded: true, // 标记未上传(按钮状态)
				uploadFileName: '',
				uploadFileList: [],
				pkgList: [],
				pkgtag: '',
				dialogSetPkg: false,
                titleSetPkg: '',
				selectedRows: [],
				noneSelectedRow: true, // 标记未选择行(按钮状态)
				tableData: [],
				curPkgId: '',
				curGroupId: -1, // 当前选择分组(默认全部)
				curGroupLabel: "全部区服列表", // 当前选择分组名
				serverGroups: [],
                needOnlineIds: [], // 选择想同步修改的在线参数
                commitDisable: false, // 提交按钮有效
                dialogUpdatePkg: false, // 提交更新配置/代码
                serverpkgs:[],
                pkgtimer: null,
                tipPkgResult: '',
                // 更新方式
                updateType: 'reboot',
                hotUpdates: [{
                        value: "reboot",
                        label: "重新启动",
                    },
                    {
                        value: "hotupdate",
                        label: "在线热更", 
                    },
                ],  
                tableHeight: document.documentElement.clientHeight-136,
			}
		},
		created: function() {
            // 打包中心httl
            this.pkgCenterApi = axios.create({
                baseURL: this.$store.state.game.pkgurl,
                timeout: 15000
            });            
			this.refreshGroupList()
			this.refreshServerList()
			this.refreshPkgList()
            this.refreshOnlineParams()
		},
		computed: {
			...mapGetters(
				['gameHttp']
			),
			// 动态上传按钮文字
			labelBtnUpload() {
				return ('config' == this.pkgtype) ? '上传配置包' : '上传代码包'
			},
            // 动态批量按钮文字
			labelBtnBatMdf() {
				return ('config' == this.pkgtype) ? '批量修改配置' : '批量修改代码'
			},            
			// 新增或编辑区服的分组时，使用可选择的项目
			selectGroups() {
				return this.serverGroups.slice(1)
			},
		},
		methods: {
            // 发送请求到打包中心
            pkgCenterHttp (cmd, data) {
                let rqs = JSON.parse(JSON.stringify(data));
                rqs.cmd = cmd;
                return this.pkgCenterApi({
                    method: 'post',
                    url: '/sygame',
                    data: rqs
                });
            },
			// 提交上传文件
			onCommitUploadPkg() {
                console.log("uploadFileList=>", this.uploadFileList);
                let uploadFileName = this.uploadFileList[0].name;
				this.pkgCenterHttp(1006, {
					name: uploadFileName,
					type: this.pkgtype,
					tag: this.pkgtag,
                    user: this.$store.state.username
				}).then((response) => {
					if (0 == response.data.errcode) {
						this.$message.success(response.data.errmsg)
						this.dialogUpload = false
						this.refreshPkgList()
					} else {
						this.$message.error(response.data.errmsg)
					}
				})
			},
			// 刷新服务器列表
			refreshServerList() {
				this.gameHttp(1008, {
					groupid: this.curGroupId,
					type: this.pkgtype
				}).then((response) => {
					this.tableData = response.data.list
				})
			},
			// 刷新分组列表
			refreshGroupList() {
				this.gameHttp(2006, {plain:1, merge:1}).then((response) => {
                    this.serverGroups = response.data.list
                    this.serverGroups.splice(0, 0, { id: -1, name: "全部区服列表"})
				})
			},
			// 刷新代码/配置包列表
			refreshPkgList() {
				this.pkgCenterHttp(1007, {
					type: this.pkgtype
				}).then((response) => {
					this.pkgList = response.data.list
				})
			},
            // 获取在线参数名列表
            refreshOnlineParams() {
				this.gameHttp(2003, {}).then((response) => {
					this.onlineParams = response.data.list
				})                
            },
			// 选择服务器分组
			onSelectGroup(item) {
                this.curGroupLabel = item.name
                this.curGroupId = item.id
                // 刷新选择结果
                this.refreshServerList()
			},
			// 弹出上传文件
			onClickUpload() {
                this.checkedPkgName = false;
				this.dialogUpload = true
				this.noneUploaded = true
				this.uploadFileList = []
				this.pkgtag = ''
			},
            // 检查是否重名
            checkExistName(name) {
				this.pkgCenterHttp(1006, {
					name: name,
					check: 1
				}).then((response) => {
                    this.dialogLoading = false;
					if (0 == response.data.errcode) {
						this.$refs.upload.submit();
					} else {
                        this.pkgtag = '';
                        this.uploadFileList = [];
                        this.checkedPkgName = false;
						this.$message.error(response.data.errmsg);
					}
				})                
            },
            // 上传预览
            onUploadPreview(file) {
                console.log("onUploadPreview=>", file.name, file)
                if (this.checkedPkgName) {
                    return;
                }
                this.dialogLoading = true;                
                this.checkedPkgName = true;
                // 用FileReader来读取
                var reader = new FileReader();
                // 重写FileReader上的readAsBinaryString方法
                FileReader.prototype.readAsBinaryString = (fp)=>{
                    let readerx = new FileReader();
                    readerx.onload = ()=>{
                        let bytes = new Uint8Array(readerx.result);
                        let zip = new admzip(Buffer.from(bytes.buffer));
                        var zipEntries = zip.getEntries(); // an array of ZipEntry records
                        let rootdirNum = 0; // 根目录下的文件夹数量
                        let otherfile = 0; // 除lua/json之外的文件数量
                        for (let i=0; i<zipEntries.length; i++) {
                             let isDir = zipEntries[i].isDirectory;
                             let filename = zipEntries[i].entryName;
                             // 根目录下的一级文件夹或文件数量统计(里面只能放一个文件夹如game或config)
                             let num = tool.charnumInsideString(filename, "/");
                             if (0==num || (isDir && 1==num)) {
                                 rootdirNum ++;
                             }
                             if (!isDir) {
                                 if (".pb"!=filename.substr(-3) && ".lua"!=filename.substr(-4) && ".json"!=filename.substr(-5)) {
                                     otherfile ++;
                                 }
                             }
                        }
                        console.log("checkresultx:", otherfile, rootdirNum);
                        if (1 != rootdirNum) {
                            this.$message.error("目录结构错误，根目录需要是单个文件夹"); this.dialogUpload=false; this.checkedPkgName=false; this.dialogLoading=false; return;
                        }
                        if (0 != otherfile) {
                            this.$message.error("压缩包中的文件，存在非正常的扩展名(仅支持.pb .lua .json)"); this.dialogUpload=false; this.checkedPkgName=false; this.dialogLoading=false; return;
                        }                        
                        this.checkExistName(file.name);
                    };
                    readerx.readAsArrayBuffer(fp);
                };
                reader.readAsBinaryString(file.raw);
            },
            onUploadSuccess(response, file) {
                console.log("onUploadSuccess=>", response)
                this.noneUploaded = false;
                this.uploadFileList.push(file)
            },
            onUploadRemove(file) {
                console.log("onUploadRemove=>", file)
                this.checkedPkgName = false;
                this.noneUploaded = true;
                this.uploadFileList = []
            },
			handleSelectionChange(val) {
				this.selectedRows = []
				for (let i = 0; i < val.length; i++) {
					this.selectedRows.push({id:val[i].id,servername:val[i].servername})
				}
				this.noneSelectedRow = 0 == val.length ? true : false
			},
			// 批量修改pkg
			onClickBatSetPKg() {
				this.dialogSetPkg = true;
                this.titleSetPkg = '批量=>选择文件包';
				this.curPkgId = ''; // 批量的不选定
                this.commitDisable = false;
                this.updateType = "reboot"; // 默认更新方式是重启
			},
			// 编辑服务器
			onClickEditServer(row) {
				this.curPkgId = row.pkgname
                if (this.curPkgId) {
                    for (let i=0; i<this.pkgList.length; i++) {
                        if (this.curPkgId == this.pkgList[i].name) {
                            this.curPkgId = this.pkgList[i]; break;
                        }
                    }
                } else {
                    this.curPkgId = '';
                }
				this.selectedRows = [{id:row.id,servername:row.servername}]
				this.dialogSetPkg = true
                this.titleSetPkg = row.servername+':'+row.servertitle + '=>选择文件包';
				this.$refs.multipleTable.clearSelection() // 单独编辑的清下选择
				this.commitDisable = false;
                this.updateType = "reboot"; // 默认更新方式是重启
			},
            // 处理选择要同步的在线参数
            onlineParamSelectionChange(rows) {
                //this.leftSelectRows = rows;
                this.needOnlineIds = [];
                for (let i=0; i<rows.length; i++) {
                    this.needOnlineIds.push(rows[i].id);
                }
                console.log("onlineParamSelectionChange=>", this.needOnlineIds);
            },
			// 提交选择pkg
			onCommitSetPkg() {
                this.serverpkgs = [];
                this.commitDisable = true;
                this.dialogUpdatePkg = true;
                this.tipPkgResult = "已提交，等待生效中...";
				this.gameHttp(1009, {
					srvids: this.selectedRows,
					pkg_id: this.curPkgId.id,
                    pkg_name: this.curPkgId.name,
					type: this.pkgtype,
                    onlines: this.needOnlineIds,
                    update_type: this.updateType, // 更新方式
				}).then((response) => {
					if (0 == response.data.errcode) {
                        let secs = 0;
                        // 定时更新状态
                        this.pkgtimer = setInterval(() => {
                            this.gameHttp(1099, {
                                uuid: response.data.uuid,
                                pkg_id: this.curPkgId.id,
                                pkg_name: this.curPkgId.name,
                                type: this.pkgtype
                            }).then((response) => {
                                if (0 == response.data.errcode) {
                                    this.serverpkgs = response.data.list;
                                    // 检查全部生效则停止计时器
                                    let total = 0;
                                    for (let i=0; i<this.serverpkgs.length; i++) {
                                        if ("成功" == this.serverpkgs[i].state) {
                                            total ++;
                                        }
                                    }
                                    if (total == this.serverpkgs.length) {
                                        clearInterval(this.pkgtimer);
                                        this.tipPkgResult = "已全部生效完成...";
                                    }
                                }
                            })
                            // 显示计时
                            this.tipPkgResult = "已提交，等待生效中..." + (++secs);
                        }, 1000);
					} else {
                        this.$message.success("提交异常");
                    }
				});
			},
            // 关闭pkg状态刷新
            onCloseCheckPkg() {
                clearInterval(this.pkgtimer);
                this.refreshServerList();
            },
            // 更新配置提示的颜色
            pkgCellStype({row, columnIndex}) {
                let stype = {padding:4+'px'};
                if (2 == columnIndex) {
                    if ("成功"==row.state) {
                        stype.color = '#00ff00';
                    } else {
                        stype.color = "#ff0000";
                    }                    
                }
                return stype;
            },
			// 状态列的标签格式化显示
			stateTagFormatEffect(statev) {
				return (2 == statev) ? "light" : "dark"
			},
			stateTagFormatTxt(statev) {
				return this.serverStates[statev - 1].label
			},
			stateTagFormatColor(statev) {
				return this.serverStates[statev - 1].tag
			},
			// 数据表色彩间隔效果     
			tableRowClassName({rowIndex}) {
				if (0 == rowIndex % 2) {
					return 'warning-row';
				} else {
					return 'success-row';
				}
			},
			////////////////////////
			////////////////////////
		},
	}
</script>

<style>
	.serverlist.el-table .warning-row {
		background: #f5f7fa;
	}

	.serverlist.el-table .success-row {
		background: #ffffff;
	}

	.serverlist.el-table {
		margin-top: 16px;
	}

	/*导航菜单字体大小*/
	.serverlist {
		font-size: 12px;
	}
    
    .dlgupload .el-dialog__body{
          display: flex;
          justify-content: center;
          align-items: center;
    }
</style>
